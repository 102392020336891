/*********************** SIXTH LANDING  ********************/

/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

h1, h2, h3, h4 {
  color: var(--text-black);
  font-family: 'Manrope', sans-serif;
}
h3{
  font-size: 2rem;
}
p{
  font-size: 1.5rem;
}
/* Variables */
:root {
 --primary-color: #FFAB01;
 --text-black:white;
 --main-bg:black;
 --bg:#18191F;
 --sixth-gradient1:linear-gradient(180deg, #8C30F5 0%, #AD72D6 100%);
 --sixth-gradient3:linear-gradient(183.4deg, #8C30F5 17.55%, #A766DC 97.19%);
 --sixth-gradient4:linear-gradient(180deg, black 17.86%, rgba(140, 48, 245, 0.9) 50.97%,black 68.63%);
 --sixth-gradient5: linear-gradient(283.54deg, #18191F 16.57%, #212229 64.54%);
 --sixth-white:rgba(255, 255, 255, 1);
 --sixthLightPurple: #F1E4FF;
 --sixthPurple: #8C30F5;
 --main-font: 'Manrope', sans-serif;
 --generic-font: 'Manrope', sans-serif;
 --btn-font: 'Manrope', sans-serif;
}

html {
  scroll-behavior: smooth;
}

/* Necessaries and Basics */
body {
  margin: 0;
  background:black;
  color: var(--text-black);
  font-family: var(--main-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
}

a {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, button, textarea, select {
  font-family: var(--main-font);
  outline: none;
  border: none;
}

input{
  font-size: 14px;
}


button {
  padding:0.9rem 3rem;
  border-radius:8px;
  font-family: var(--btn-font);
  font-weight: 500;
}

button:hover {
  opacity: 1;
  cursor: pointer;
}

.navigation-text {
  font-weight: 100;
  font-size: 14px;
  color: var(--text-black);
  padding:1rem 0 0.5rem 0;
  cursor: pointer;
}
div{
}

@media (max-width:450px){
  .navigation-text {
    color: var(--text-black);
  }
  input{
    font-size: 16px;
  }
 h3{
  font-size: 1.5rem;
 }
}

.navigation-text:hover {
  opacity:0.8;
}
.active {
  font-weight:500;
  border-bottom:0.5px solid #fff;
}

/* General Style */
.main-widget {
  margin:0 auto;
  padding: 0;
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1800px) {
  .main-widget{
    
  }
}



/* Scroll Bar Override */

/* width */
::-webkit-scrollbar {
  width: 5px;

  border-radius: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9a8a8;
  cursor:pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
  width: 20px;
  cursor:pointer;
}

@media (max-width:1028px){
  ::-webkit-scrollbar-thumb{
      height:1px;
      display:none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height:1px;
    display:none;
    border-radius: 1rem;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:none;
    display:none;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    display:none;
  }
}

  /* Media Query for Mobile Devices */
  @media (max-width: 480px) {
    
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
    
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
    
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {

}

/* Media Query for Large screens */
@media (min-width: 1281px) {
    
}